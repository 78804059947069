/* */ 
'use strict';
var core = require('./$.core'),
    $ = require('./$'),
    DESCRIPTORS = require('./$.descriptors'),
    SPECIES = require('./$.wks')('species');
module.exports = function(KEY) {
  var C = core[KEY];
  if (DESCRIPTORS && C && !C[SPECIES])
    $.setDesc(C, SPECIES, {
      configurable: true,
      get: function() {
        return this;
      }
    });
};
